<template>
    <slot :open="open" v-if="!noDefaultSlot">
        <button class="btn btn-link btn-sm" @click="open"
            :title="$t('View current record info')"
            :disabled="currentRow?.ID == null && currentRow?.PrimKey == null">
            <i class="bi bi-info-circle"></i>
        </button>
    </slot>
    <OModal ref="modalRef" @show="getRecordInfo" @hidden="onHidden">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Record info for selected row') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <template v-if="procedureError">
                        <div class="d-flex mx-2">
                            <i class="bi bi-exclamation-triangle-fill text-danger me-2"></i>
                            <span>{{procedureError}}</span>
                        </div>
                    </template>
                    <template v-else>
                        <div class="record-info-item placeholder-glow">
                            <h6>{{ $t('ID/PrimKey') }}</h6>
                            <span v-if="isLoading" class="placeholder w-75"></span>
                            <span v-else>{{ recordInfo.ID ?? '-' }} / {{ recordInfo.PrimKey ?? '-' }}</span>
                        </div>
                        <div class="record-info-item placeholder-glow">
                            <h6>{{ $t('Created') }}</h6>
                            <span v-if="isLoading" class="placeholder w-50"></span>
                            <span v-else v-person-hover="recordInfo.CreatedBy_ID">{{ recordInfo.Created ? $formatDate(recordInfo.Created, 'General Date Short Time') : '-' }} / {{ recordInfo.CreatedBy ?? '-' }} ({{recordInfo.CreatedBy_ID ?? '-'}})</span>
                        </div>
                        <div class="record-info-item placeholder-glow">
                            <h6>{{ $t('Updated') }}</h6>
                            <span v-if="isLoading" class="placeholder w-50"></span>
                            <span v-else v-person-hover="recordInfo.UpdatedBy_ID">{{ recordInfo.Updated ? $formatDate(recordInfo.Updated, 'General Date Short Time') : '-' }} / {{ recordInfo.UpdatedBy ?? '-' }} ({{recordInfo.UpdatedBy_ID ?? '-'}})</span>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup lang="ts">
import OModal from 'o365.vue.components.Modal.vue';
import { getDataObjectById } from 'o365.vue.ts';
import { getOrCreateProcedure } from 'o365.vue.ts';
import vPersonHover from 'o365.vue.directive.personHover.ts';
import { ref, computed } from 'vue';

export interface IRecordInfoProps {
    dataObject?: any,
    row?: any,
    noDefaultSlot?: boolean
};

const props = defineProps<IRecordInfoProps>();

const emit = defineEmits<{
    (e: 'hidden'): void
}>();

const modalRef = ref<any>(null);
const recordInfo = ref({});
const isLoading = ref(true);
const procedureError = ref(null);
const procRecordInfo = getOrCreateProcedure({
    id: 'o_procRecordInfo',
    procedureName: 'sstp_O365_GetRecordInfo',
});

const currentDataObject = computed(() => {
    return props.dataObject ?? getDataObjectById(props.row?.dataObjectId, props.row?.appId)
});
const currentRow = computed(() => {
    return props.row ?? props.dataObject.current;
});


async function getRecordInfo() {
    recordInfo.value = {};
    isLoading.value = true;
    procedureError.value = null;
    try {
        const result = await procRecordInfo.execute({ 
            PrimKey: currentRow.value.PrimKey,
            ID: String(currentRow.value.ID),
            View: currentDataObject.value.viewName,
        });
        recordInfo.value = result.Table[0];
    } catch (ex) {
        procedureError.value = ex;
    }
    isLoading.value = false;
}

function onHidden() {
    emit('hidden');
}

function open() {
    modalRef.value.show();
}

defineExpose({ modalRef, open });

</script>

<style scoped>
.record-info-item {
    margin-bottom: 1rem;
}
</style>